import $ from 'jquery';
require("../__vendor/owl");

const services = () => {
   $(".owl-carousel").owlCarousel({
       loop: true,
       responsive: {
           0: {
               items: 1,
               stagePadding: 90,
               margin: 15
           },
           768: {
                items: 2,
                stagePadding: 60,
                margin: 15
           },
           992: {
               items: 1,
               stagePadding: 100,
               margin: 15
           },
           1400: {
               items: 2,
               stagePadding: 100,
               margin: 30
           }
       }
   });
};

export default services;