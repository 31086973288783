require("../__vendor/SplitText.min.js");

const animations = () => {
    if($(".line-effect").length) {
        new SplitText(".line-effect", {
            type: "lines"
        })

        $(".line-effect").addClass("visible");
    }
};

export default animations;