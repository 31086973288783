import $ from 'jquery';
require("../__vendor/owl");

const references = () => {
   $(".owl-carousel").owlCarousel({
      items: 1,
      loop: true,
      margin: 15,
      stagePadding: 45,
      responsive: {
         768: {
            items: 2,
            margin: 30
         },
         992: {
            items: 3,
            stagePadding: 114,
            margin: 30
         },
         1400: {
            items: 3,
            stagePadding: 175,
            margin: 30 
         }
      }
   });

   $( ".owl-prev").html('<img src="http://everast.de/wp-content/uploads/2022/06/arrow-left.svg" alt="">');
   $( ".owl-next").html('<img src="http://everast.de/wp-content/uploads/2022/06/arrow-left.svg" alt="">');
};

export default references;