import Swiper, { Navigation, Pagination } from 'swiper';

const details = () => {
    Swiper.use([Navigation, Pagination]);

    new Swiper(".details-slider", {
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        }
    });
};

export default details;