import "@babel/polyfill";
import $ from 'jquery';
import AOS from 'aos';
import loader from './__modules/loader';
import details from "./__modules/details";
import services from "./__modules/services";
import references from "./__modules/references";
import animations from "./__modules/animations";

document.addEventListener('DOMContentLoaded', () => {
	// AOS Init
	AOS.init({
		startEvent: 'DOMContentLoaded',
		offset: 100,
		delay: 0,
		duration: 400, 
		easing: 'ease',
		once: true,
		animatedClassName: 'animated'
	});

	// Animations Init
	animations();

	// Menu add class parents 
	$(".menu li").each(function() {
        if($(this).find("ul").length) {
            $(this).addClass("has-sub");
        }
    });

	// Details Slider Init
	if($(".details-slider").length) {
		details();
	}

	// Reference Slider Init
	if($(".references-slider").length) {
		references();
	}

	// Menubar click
	$(".menubar").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("active");
		$(".menu").toggleClass("active");
	});

	// Service Slider Init
	if($(".services-slider").length) {
		services();
	}

	// Menubar
	if($(window).width() < 992) {
		$(".menu a").on("click", function(e) {
			if($(this).closest('li').has('> div, > ul').length) {
				e.preventDefault();

				$(this).closest('li').toggleClass("active");
				$(this).closest('li').find('> div, > ul').slideToggle(400);
			}
		});
	}

	// Subcategory Item Click
	$(".subcategory-item").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("active");
		$(".subcategory-menu").slideToggle();
	});
});

window.addEventListener('load', () => {
	// Loader Init
	if($(".loader").length) {
		loader();
	}

	if($(window).width() < 991) {
		let contactLink = $(".contact-btn a").attr("href"),
			contactText = $(".contact-btn a").text();
		$(".menu").append("<li class='menu-btn'><a href='"+ contactLink +"'>"+contactText+"</a></li>")
	}
});

window.addEventListener('resize', () => {
	if($(window).width() > 991) {
		$(".menu-btn").remove();
	} else {
		if($(".menu-btn").length === 0) {
			let contactLink = $(".contact-btn a").attr("href"),
				contactText = $(".contact-btn a").text();
			$(".menu").append("<li class='menu-btn'><a href='"+ contactLink +"'>"+contactText+"</a></li>")
		}
	}
});

window.addEventListener('scroll', () => {

	// On menu scroll add class
	if($(window).scrollTop() > 0) {
		$(".header").addClass("header-scrolled");
	} else {
		$(".header").removeClass("header-scrolled");
	}

});