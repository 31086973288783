import { gsap, Power4 } from 'gsap/all';
require("../__vendor/SplitText.min.js");

const loader = () => {
    if(!localStorage.getItem("everast-loaded")) {
        var loaderTl = new gsap.timeline({
            paused: true
        });

        loaderTl.to(".line-yellow", 1, {
            "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            ease: Power4.ease
        }).to(".line-r", 1, {
            "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            ease: Power4.ease
        }, 0.3).staggerTo(".line-left", 1, {
            "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
        }, -0.1, 0.3).to(".loader svg", 0.5, {
            scale: 1,
            x: "-50%",
            ease: Power4.ease
        }, 2).staggerTo(".letter", 1.5, {
            autoAlpha: 1
        }, 0.15, 2.25).set(".loader", {
            className: "+= loader active"
        }, 3.5).set(".loader", {
            className: "+= loader active hide"
        }, 3.9);

        localStorage.setItem("everast-loaded", true);

        loaderTl.play();
    } else {
        $(".loader").remove();
    }
};

export default loader;